export default {
  BUTTON_PRIMARY_CLASS: 'cm_button cm_button__primary',
  BUTTON_SECONDARY_CLASS: 'cm_button cm_button__secondary',

  SEARCH_PAGE_CLASS:
    "{this.responseState === 'nothing' || this.responseState === 'badRequest' ? 'cm_hide' : ''}",

  PRODUCT_REPEATER_CLASS: 'js-grid',
  SORT_BY: 'SORT BY <rt-virtual template="icons/angle-down" />',
  LOAD_MORE_TEXT: 'LOAD MORE',

  FILTERS_ICON: '<rt-virtual template="icons/angle-down" />',

  SEARCH_BOX_CLEAR_BUTTON: '✕',

  SELECT_YOUR_VEHICLE: `
    <h2>Select Your Vehicle</h2>
    <p>Shop for your specific vehicle to find parts that fit.</p>`,

  VERIFY_FITMENT_TITLE: 'CHECK IF THIS FITS YOUR VEHICLE',
  VERIFY_FITMENT_FITS: 'This product fits your vehicle',
  VERIFY_FITMENT_NOT_FIT: "This product doesn't fit your vehicle",
  VERIFY_FITMENT_ICON: `
    <rt-virtual rt-if="this.fits === 'yes'" template="icons/check" />
    <rt-virtual rt-if="this.fits === 'universal'" template="icons/exclamation" />
    <rt-virtual rt-if="this.fits === 'no'" template="icons/error" />
    <rt-virtual rt-if="this.fits === 'unknown'" template="icons/exclamation" />`,
};
